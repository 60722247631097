import { environment } from "../../../environments/environment";

export interface SideBarItem {
    name: string;
    name2?: string;
    icon?: string;
    link?: string;
    externalLink?: string;
    child?: SideBarItem[];
    hideSidebarOnClick?: boolean;
    pageId?: string;
}

export const SIDE_BAR_ITEMS: { [key: string]: SideBarItem[] } = {
    Q2O: [
        {
            name: "Personal Tasks",
            child: [
                {
                    name: "Inbox",
                    icon: "inbox",
                    link: "/q2o/personal-tasks/inbox",
                    pageId: "21",
                },
                {
                    name: "Draft",
                    icon: "edit_note",
                    link: "/q2o/personal-tasks/draft",
                    pageId: "22",
                },
                {
                    name: "Sent",
                    icon: "send",
                    link: "/q2o/personal-tasks/sent",
                    pageId: "23",
                },
                {
                    name: "Delegate",
                    icon: "people_alt",
                    link: "/q2o/personal-tasks/delegate",
                    pageId: "24",
                },
            ],
        },
        {
            name: "Q2O Request",
            child: [
                {
                    name: "Create Cost Structure",
                    icon: "add_box",
                    link: "/q2o/request/create-cost-structure",
                    hideSidebarOnClick: true,
                    pageId: "25,26,27",
                },
                {
                    name: "Create Cost Structure",
                    name2: "from template",
                    icon: "assignment",
                    link: "/q2o/request/create-cs-from-template",
                    pageId: "25,26,27",
                }
            ],
        },
        {
            name: "Report",
            child: [
                {
                    name: "Q2O Tracking Report",
                    icon: "equalizer",
                    link: "/q2o/report/q2o-tracking-report",
                    pageId: "29,30",
                }
            ],
        },
        {
            name: "Relate WebSite",
            child: [
                {
                    name: "EIDC",
                    icon: "logout",
                    externalLink: environment.EIDC_URL,
                    pageId: "40",
                },
                {
                    name: "BPPS",
                    icon: "logout",
                    externalLink: environment.BPPS_URL,
                    pageId: "41",
                },
                {
                    name: "MMG",
                    icon: "logout",
                    externalLink: environment.MMG_URL,
                    pageId: "42",
                },
                {
                    name: "Bulk SO/PO Cancellation",
                    icon: "logout",
                    externalLink: environment.BULK_SO_PO_CANCELLATION_URL,
                    pageId: "37",
                },
                {
                    name: "Re Create Order",
                    icon: "logout",
                    externalLink: environment.RE_CREATE_ORDER_URL,
                    pageId: "38",
                },
                {
                    name: "Order Transaction",
                    name2: "Unlocking",
                    icon: "logout",
                    externalLink: environment.ORDER_TRN_UNLOCKING_URL,
                    pageId: "39",
                },
            ],
        },
    ],
    FWD: [
        {
            name: "Personal Tasks",
            child: [
                {
                    name: "Inbox",
                    icon: "inbox",
                    link: "/fwd/personal-tasks/inbox",
                    pageId: "4",
                },
                {
                    name: "Draft",
                    icon: "edit_note",
                    link: "/fwd/personal-tasks/draft",
                    pageId: "5",
                },
                {
                    name: "Sent",
                    icon: "send",
                    link: "/fwd/personal-tasks/sent",
                    pageId: "6",
                },
                {
                    name: "Delegate",
                    icon: "people_alt",
                    link: "/fwd/personal-tasks/delegate",
                    pageId: "7",
                },
            ],
        },
        {
            name: "FWD Request",
            child: [
                {
                    name: "Indicative Rate",
                    icon: "monetization_on",
                    link: "/fwd/request/indicative-rate",
                    pageId: "13,14,15",
                },
                {
                    name: "Create FWO Request",
                    icon: "add_circle",
                    link: "/fwd/request/create-fwo-request",
                    pageId: "8,9,10,11,12",
                },
            ],
        },
        {
            name: "Report",
            child: [
                {
                    name: "FWD Tracking Report",
                    icon: "bar_chart",
                    link: "/fwd/report/fwd-tracking",
                    pageId: "16,17",
                },
                {
                    name: "FWD Request and",
                    name2: "Mapping Summary Report",
                    icon: "bar_chart",
                    link: "/fwd/report/fwd-request-mapping-summary",
                    pageId: "18,19",
                },
                {
                    name: "AFMS Interface Monitoring",
                    icon: "bar_chart",
                    link: "/fwd/report/afms-interface-monitoring",
                    pageId: "45"
                }
            ],
        },
    ],
    MASTERDATA: [
        {
            name: "Maintain Master Data",
            child: [
                {
                    name: "Product Group ",
                    icon: "category",
                    link: "/masterdata/product-group",
                    pageId: "1",
                },
                {
                    name: "Port Master",
                    icon: "waves",
                    link: "/masterdata/port-master",
                    pageId: "2",
                },
                {
                    name: "Shipment Operation",
                    name2: "Email",
                    icon: "email",
                    link: "/masterdata/shipment-operation-email",
                    pageId: "3",
                },
                {
                    name: "Authorize - User",
                    icon: "perm_identity",
                    link: "/masterdata/user",
                    pageId: "31",
                },
                {
                    name: "Authorize - Role",
                    icon: "perm_identity",
                    link: "/masterdata/role",
                    pageId: "32",
                },
                {
                    name: "Authorize - Permission",
                    icon: "perm_identity",
                    link: "/masterdata/permission",
                    pageId: "33",
                },
            ],
        },
    ],
};
