import { Component } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { NavigationEnd, Router } from "@angular/router";

interface BreadCrumbItem {
    displayName: string;
    link?: string | null;
}

@Component({
    selector: "app-breadcrumb",
    standalone: true,
    imports: [SharedModule],
    templateUrl: "./breadcrumb.component.html",
    styleUrl: "./breadcrumb.component.scss",
})
export class BreadcrumbComponent {
    breadCrumbItems: BreadCrumbItem[] = [];

    // Mapping of route url and name
    mapping: { [key: string]: string[] } = {
        q2o: ["Q2O Process", "/q2o"],
        fwd: ["FWD Process", "/fwd"],
        request: ["Request"],
        "indicative-rate": ["Indicative Rate"],
        "create-cost-structure": ["Create Cost Structure"],
        "create-cs-from-template": ["Create CS from template"],
        "edit-cost-structure-template": ["Edit Cost Structure template"],
        masterdata: ["Master Data", "/masterdata"],
        "product-group": ["Product Group"],
        "shipment-operation-email": ["Shipment Operation Email"],
        "port-master": ["Port Master"],
        "create-fwo-request": ["Create FWO Request"],
        "edit-fwo-request": ["Edit FWO Request"],
        "apploval-fwo-request": ["Approval FWO Request"],
        "view-fwo-request": ["View FWO Request"],
        "personal-tasks": ["Personal Tasks"],
        inbox: ["Inbox"],
        draft: ["Draft"],
        sent: ["Sent"],
        delegate: ["Delegate"],
        home: ["Home"],
        "fwd-tracking": ["FWD Tracking"],
        "fwd-request-mapping-summary": ["FWD Request Mapping Summary"],
        "afms-interface-monitoring": ["AFMS Interface Monitoring"]
    };

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urls: string[] = this.router.url.split("?")[0].split("/");
                this.breadCrumbItems = urls.map((path) => {
                    if (this.mapping[path]) {
                        return { displayName: this.mapping[path][0], link: this.mapping[path][1] ?? null };
                    }
                    return { displayName: path, link: null };
                });
            }
        });
    }

    navigateToHome() {
        this.router.navigateByUrl('/home');
    }
}
